import { useState, useEffect } from 'react';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { Receipt } from '@material-ui/icons';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import DummyText from 'components/dummy-text';
import { getTierAndSVGByBrand } from 'utils/loyalty-tier';

import { BRAND } from '../../constants';
import { useStyles } from './styles';
import { SummaryProps, actionType, annotationType } from './types';

const Summary = ({
  actions,
  annotations,
  content,
  header,
  icon,
  isLoading,
  subheader,
  preventBlinking,
  tierLevel,
  enableCustomerTierForAll,
}: SummaryProps) => {
  const classes = useStyles();

  const [blink, setBlink] = useState(false);
  useEffect(() => {
    setBlink(true);
    const timer = setTimeout(() => {
      setBlink(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [subheader]);

  const tierInfo = getTierAndSVGByBrand(BRAND.toLocaleUpperCase(), tierLevel);
  const { formatMessage } = useIntl();

  const showTierLevelLabel = isLoading ? (
    <DummyText width={100} />
  ) : (
    formatMessage({ id: `loyalty.${tierInfo?.label}` })
  );
  const showTierLevelIcon = isLoading ? undefined : (
    <Avatar className={classes.tierLevelAvatar}>{tierInfo?.icon}</Avatar>
  );

  const renderAnnotations = (_annotations?: Array<annotationType>) => {
    return (
      <>
        {_annotations
          ? _annotations.map((annotation: annotationType, index: number) => (
              <div className={classes.chipsWrapper} key={index}>
                <Tooltip title={annotation?.tooltipText || ''}>
                  <>
                    {enableCustomerTierForAll && (
                      <Chip label={showTierLevelLabel} avatar={showTierLevelIcon} />
                    )}
                    <Chip
                      label={isLoading ? <DummyText width={100} /> : annotation?.label}
                      avatar={
                        isLoading ? undefined : (
                          <Avatar className={classes.avatar}>
                            <Receipt
                              fontSize="small"
                              className={clsx(classes.icon, classes.avatarText)}
                            />
                          </Avatar>
                        )
                      }
                    />
                  </>
                </Tooltip>
              </div>
            ))
          : null}
      </>
    );
  };
  const renderActions = (_actions?: Array<actionType>) => {
    return isLoading ? (
      <DummyText width={120} />
    ) : (
      <>
        {_actions
          ? _actions.map((action: actionType, index: number) => (
              <Button
                color="primary"
                disabled={isLoading || action.disabled}
                disableElevation
                key={index}
                onClick={action.onClick}
                size="small"
                variant="contained"
              >
                {action?.label}
              </Button>
            ))
          : null}
      </>
    );
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={header}
        subheader={
          isLoading ? (
            <DummyText width={120} />
          ) : (
            <span className={blink === true && !preventBlinking ? classes.blink : classes.default}>
              {subheader}
            </span>
          )
        }
        avatar={icon}
        titleTypographyProps={{ variant: 'h6' }}
        action={renderAnnotations(annotations)}
      />
      <CardContent>{isLoading ? <DummyText /> : content ?? null}</CardContent>
      {!!actions ? (
        <CardActions className={classes.actions}>{renderActions(actions)}</CardActions>
      ) : null}
    </Card>
  );
};

export { Summary };
