import { FC } from 'react';

const TierBadgeBKSuperKing: FC = ({ size }: any) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_4145_9826" fill="white">
        <path d="M9.10214 0.808761L2.82862 4.60912C1.63204 5.33398 0.901123 6.63133 0.901123 8.03034V16.1759C0.901123 17.6054 1.66399 18.9262 2.90221 19.6406L9.17475 23.2594C10.3976 23.9649 11.9018 23.9736 13.1327 23.2821L19.6294 19.6326C20.89 18.9245 21.6704 17.5911 21.6704 16.1452L21.6704 8.06139C21.6704 6.64563 20.922 5.33533 19.7025 4.61606L13.2068 0.784657C11.9385 0.0365328 10.3616 0.0457934 9.10214 0.808761Z" />
      </mask>
      <path
        d="M9.10214 0.808761L2.82862 4.60912C1.63204 5.33398 0.901123 6.63133 0.901123 8.03034V16.1759C0.901123 17.6054 1.66399 18.9262 2.90221 19.6406L9.17475 23.2594C10.3976 23.9649 11.9018 23.9736 13.1327 23.2821L19.6294 19.6326C20.89 18.9245 21.6704 17.5911 21.6704 16.1452L21.6704 8.06139C21.6704 6.64563 20.922 5.33533 19.7025 4.61606L13.2068 0.784657C11.9385 0.0365328 10.3616 0.0457934 9.10214 0.808761Z"
        fill="#8A1600"
      />
      <path
        d="M2.82862 4.60912L2.05144 3.32616L2.05144 3.32616L2.82862 4.60912ZM9.10214 0.808761L9.87933 2.09172V2.09172L9.10214 0.808761ZM13.2068 0.784657L13.9689 -0.50734V-0.50734L13.2068 0.784657ZM19.7025 4.61606L18.9405 5.90806V5.90806L19.7025 4.61606ZM21.6704 8.06139L23.1704 8.06139V8.06139H21.6704ZM21.6704 16.1452H20.1704H21.6704ZM19.6294 19.6326L20.3641 20.9404L19.6294 19.6326ZM13.1327 23.2821L13.8674 24.5899H13.8674L13.1327 23.2821ZM9.17475 23.2594L8.42516 24.5587H8.42516L9.17475 23.2594ZM2.90221 19.6406L2.15262 20.9399L2.90221 19.6406ZM3.60581 5.89208L9.87933 2.09172L8.32495 -0.474196L2.05144 3.32616L3.60581 5.89208ZM12.4448 2.07665L18.9405 5.90806L20.4646 3.32407L13.9689 -0.50734L12.4448 2.07665ZM20.1704 8.06139L20.1704 16.1452L23.1704 16.1452L23.1704 8.06139L20.1704 8.06139ZM18.8948 18.3248L12.3981 21.9743L13.8674 24.5899L20.3641 20.9404L18.8948 18.3248ZM9.92435 21.9602L3.6518 18.3413L2.15262 20.9399L8.42516 24.5587L9.92435 21.9602ZM2.40112 16.1759V8.03034H-0.598877V16.1759H2.40112ZM3.6518 18.3413C2.87792 17.8949 2.40112 17.0693 2.40112 16.1759H-0.598877C-0.598877 18.1415 0.450069 19.9576 2.15262 20.9399L3.6518 18.3413ZM12.3981 21.9743C11.6288 22.4065 10.6886 22.4011 9.92434 21.9602L8.42516 24.5587C10.1066 25.5288 12.1749 25.5406 13.8674 24.5899L12.3981 21.9743ZM20.1704 16.1452C20.1704 17.0489 19.6827 17.8822 18.8948 18.3248L20.3641 20.9404C22.0974 19.9667 23.1704 18.1333 23.1704 16.1452H20.1704ZM18.9405 5.90806C19.7026 6.3576 20.1704 7.17654 20.1704 8.06139H23.1704C23.1704 6.11472 22.1413 4.31306 20.4646 3.32407L18.9405 5.90806ZM9.87933 2.09172C10.6665 1.61486 11.652 1.60908 12.4448 2.07665L13.9689 -0.50734C12.2249 -1.53601 10.0567 -1.52328 8.32495 -0.474196L9.87933 2.09172ZM2.05144 3.32616C0.406137 4.32285 -0.598877 6.1067 -0.598877 8.03034H2.40112C2.40112 7.15596 2.85795 6.34512 3.60581 5.89208L2.05144 3.32616Z"
        fill="#FF8732"
        mask="url(#path-1-inside-1_4145_9826)"
      />
      <g filter="url(#filter0_d_4145_9826)">
        <path
          d="M15.6363 9.65775L14.8302 10.3359L14.0242 11.0141C13.9022 11.1151 13.76 11.1564 13.6228 11.1357C13.4857 11.1172 13.3536 11.0389 13.2537 10.9069L12.5357 9.95459L11.8177 9.00224C11.6754 8.8126 11.4807 8.71777 11.286 8.71777C11.0912 8.71777 10.8965 8.8126 10.7542 9.00224L10.0362 9.95459L9.31825 10.9069C9.21834 11.0389 9.08626 11.1172 8.94909 11.1357C8.81193 11.1543 8.66968 11.1151 8.54776 11.0141L7.74171 10.3359L6.93566 9.65775C6.72907 9.4846 6.48522 9.49697 6.30064 9.63095C6.11607 9.76494 5.98906 10.0164 6.01446 10.3215L6.24815 13.1456L6.48184 15.9696C6.49708 16.1531 6.5682 16.3139 6.67319 16.4293C6.77818 16.5447 6.91704 16.6148 7.06944 16.6148H11.286H15.5025C15.6532 16.6148 15.7937 16.5447 15.8987 16.4293C16.0037 16.3139 16.0748 16.1531 16.0901 15.9696L16.3238 13.1456L16.5575 10.3215C16.5829 10.0164 16.4558 9.76494 16.2713 9.63095C16.085 9.49697 15.8428 9.4846 15.6363 9.65775Z"
          fill="#FFAA00"
        />
        <g opacity="0.4">
          <path
            d="M15.0256 7.90753L14.3327 8.4996L13.6398 9.09166C13.535 9.17984 13.4127 9.21583 13.2948 9.19784C13.1769 9.18164 13.0633 9.11326 12.9774 8.99808L12.3602 8.16667L11.743 7.33526C11.6207 7.1697 11.4533 7.08691 11.2859 7.08691C11.1185 7.08691 10.9511 7.1697 10.8288 7.33526L10.2116 8.16667L9.59434 8.99808C9.50845 9.11326 9.39491 9.18164 9.27699 9.19784C9.15908 9.21404 9.0368 9.17984 8.93199 9.09166L8.23907 8.4996L7.54615 7.90753C7.36855 7.75636 7.15893 7.76716 7.00026 7.88414C6.84159 8.00111 6.73241 8.22066 6.75424 8.487L6.95513 10.9524L7.15602 13.4179C7.16912 13.5781 7.23026 13.7184 7.32052 13.8192C7.41077 13.92 7.53014 13.9812 7.66115 13.9812H11.2859H14.9106C15.0402 13.9812 15.161 13.92 15.2512 13.8192C15.3415 13.7184 15.4026 13.5781 15.4157 13.4179L15.6166 10.9524L15.8175 8.487C15.8394 8.22066 15.7302 8.00111 15.5715 7.88414C15.4114 7.76716 15.2032 7.75636 15.0256 7.90753Z"
            fill="#FFAA00"
          />
        </g>
        <path
          d="M14.432 12.2036C14.3647 12.8559 13.9253 13.2413 13.6917 13.3396C14.2301 13.5082 14.4042 14.1516 14.4363 14.4866C14.5134 13.7526 14.9704 13.3451 15.1723 13.3396C14.7012 13.182 14.432 12.5298 14.432 12.2036Z"
          fill="#F5EBDC"
        />
        <path
          d="M8.21346 12.2036C8.14616 12.8559 7.70677 13.2413 7.47314 13.3396C8.01155 13.5082 8.18571 14.1516 8.21784 14.4866C8.29494 13.7526 8.75187 13.3451 8.95377 13.3396C8.48266 13.182 8.21346 12.5298 8.21346 12.2036Z"
          fill="#F5EBDC"
        />
        <path
          d="M11.3222 11.2256C11.1876 12.437 10.3088 13.1528 9.84155 13.3354C10.9184 13.6484 11.2667 14.8433 11.3309 15.4654C11.4851 14.1022 12.399 13.3455 12.8028 13.3354C11.8606 13.0427 11.3222 11.8313 11.3222 11.2256Z"
          fill="#F5EBDC"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4145_9826"
          x="3.81303"
          y="3.72363"
          width="14.946"
          height="18.8383"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.14327" />
          <feGaussianBlur stdDeviation="0.571635" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4145_9826" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4145_9826"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default TierBadgeBKSuperKing;
