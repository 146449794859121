import { useMemo } from 'react';

import { TableRow, TableCell } from '@material-ui/core';

import CopyableText from 'components/copyable-text';
import DummyText from 'components/dummy-text';
import TextLink from 'components/text-link';
import { EMPTYDATA } from 'components/utils/constants';

import { twoColumnTableProps } from './types';

const TwoColumnTableRow = ({
  headerText,
  headerBold = true,
  loading = false,
  empty = false,
  value,
  fallback = <>{EMPTYDATA}</>,
  copyable = false,
  link,
  multiline = false,
  className = '',
  secondLineHeader = undefined,
}: twoColumnTableProps) => {
  const cellContent = useMemo(() => {
    if (loading) {
      return <DummyText />;
    }
    if (copyable) {
      return <CopyableText annotation={headerText} text={value} />;
    }
    if (link) {
      return <TextLink to={link}>{value}</TextLink>;
    }
    if (empty) {
      return fallback;
    }
    return value;
  }, [loading, copyable, link, empty, fallback, value, headerText]);

  return (
    <TableRow>
      {headerBold ? (
        <TableCell scope="row">
          <strong>{headerText}</strong>
          {secondLineHeader && <div>{secondLineHeader}</div>}
        </TableCell>
      ) : (
        <TableCell scope="row">{headerText}</TableCell>
      )}

      <TableCell
        style={{
          overflow: multiline ? 'unset' : 'hidden',
          whiteSpace: multiline ? 'unset' : 'nowrap',
          textOverflow: multiline ? 'unset' : 'ellipsis',
        }}
        className={className}
      >
        {cellContent}
      </TableCell>
    </TableRow>
  );
};

export default TwoColumnTableRow;
