import TierBadgeBKKing from 'components/common/icons/tier-badge-bk-king';
import TierBadgeBKSuperKing from 'components/common/icons/tier-badge-bk-superking';
import TierBadgeFHSNormal from 'components/common/icons/tier-badge-fhs-normal';
import TierBadgeFHSSuper from 'components/common/icons/tier-badge-fhs-super';
import TierBadgePLKNormal from 'components/common/icons/tier-badge-plk-normal';
import TierBadgePLKSuper from 'components/common/icons/tier-badge-plk-super';
import { TIER_LABEL } from 'components/utils/constants';

interface TierInfo {
  label: string;
  icon: any;
}

type BrandMap = Map<string, TierInfo>;

export const getTierAndSVGByBrand = (brand: string, tier?: string, size = 30) => {
  const tierByBrandMap: Record<string, BrandMap> = {
    BK: new Map<string, TierInfo>([
      ['Tier_1', { label: TIER_LABEL.KING, icon: TierBadgeBKKing }],
      ['Tier_2', { label: TIER_LABEL.SUPERKING, icon: TierBadgeBKSuperKing }],
    ]),
    FHS: new Map<string, TierInfo>([
      ['Tier_1', { label: TIER_LABEL.NORMAL, icon: TierBadgeFHSNormal }],
      ['Tier_2', { label: TIER_LABEL.SUPER, icon: TierBadgeFHSSuper }],
    ]),
    PLK: new Map<string, TierInfo>([
      ['Tier_1', { label: TIER_LABEL.NORMAL, icon: TierBadgePLKNormal }],
      ['Tier_2', { label: TIER_LABEL.SUPER, icon: TierBadgePLKSuper }],
    ]),
  };

  const selectedMap = tierByBrandMap[brand];

  const tierInfo = tier ? selectedMap?.get(tier) : undefined;

  if (tierInfo) {
    const IconComponent = tierInfo.icon;
    return {
      label: tierInfo.label,
      icon: <IconComponent size={size} />,
    };
  }
  return null;
};
