import { FC } from 'react';

const TierBadgeBKKing: FC = ({ size }: any) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.82165 1.21954L1.84615 6.18896C1.26005 6.55415 0.903809 7.19586 0.903809 7.88642V18.3467C0.903809 19.0527 1.27602 19.7064 1.88316 20.0666L9.85817 24.7991C10.4802 25.1683 11.2531 25.1728 11.8794 24.8109L20.0967 20.0629C20.7152 19.7056 21.0961 19.0455 21.0961 18.3312L21.0961 7.90204C21.0961 7.20301 20.7311 6.5547 20.1335 6.19212L11.9167 1.20707C11.2719 0.815859 10.4618 0.820678 9.82165 1.21954Z"
        fill="#F5EBDC"
      />
      <path
        d="M15.2297 10.0134L14.4461 10.7033L13.6624 11.3932C13.5439 11.496 13.4056 11.5379 13.2722 11.5169C13.1389 11.4981 13.0105 11.4184 12.9133 11.2842L12.2153 10.3153L11.5172 9.34652C11.3789 9.15359 11.1896 9.05713 11.0003 9.05713C10.8109 9.05713 10.6216 9.15359 10.4833 9.34652L9.78527 10.3153L9.08723 11.2842C8.99009 11.4184 8.86168 11.4981 8.72832 11.5169C8.59497 11.5358 8.45668 11.496 8.33814 11.3932L7.55448 10.7033L6.77082 10.0134C6.56997 9.83722 6.3329 9.84981 6.15344 9.98611C5.97399 10.1224 5.85052 10.3783 5.87521 10.6886L6.10241 13.5615L6.3296 16.4345C6.34442 16.6211 6.41357 16.7847 6.51564 16.9021C6.61771 17.0195 6.75271 17.0908 6.90088 17.0908H11.0003H15.0997C15.2462 17.0908 15.3828 17.0195 15.4849 16.9021C15.587 16.7847 15.6561 16.6211 15.6709 16.4345L15.8981 13.5615L16.1253 10.6886C16.15 10.3783 16.0266 10.1224 15.8471 9.98611C15.666 9.84981 15.4306 9.83722 15.2297 10.0134Z"
        fill="#FFAA00"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8076 9.05713C10.6633 9.09096 10.5258 9.18246 10.4189 9.33164L9.72082 10.3005L9.02277 11.2693C8.92564 11.4035 8.79722 11.4832 8.66387 11.5021C8.53052 11.5209 8.4262 11.4811 8.30766 11.3783L7.53843 10.6737L6.7692 9.97123C6.56835 9.79508 6.26844 9.83493 6.08899 9.97123C5.90954 10.1075 5.78606 10.3634 5.81076 10.6737L6.03795 13.5467L6.26515 16.4196C6.27997 16.6062 6.34911 16.7698 6.45119 16.8872C6.55326 17.0047 6.68826 17.076 6.83643 17.076H10.8076V9.05713Z"
        fill="#D9940C"
      />
    </svg>
  );
};

export default TierBadgeBKKing;
