import { FC } from 'react';

const TierBadgePLKNormal: FC = ({ size }: any) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 54 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.564 1.74549L3.86271 13.1555C1.93616 14.2713 0.75 16.329 0.75 18.5553V41.9881C0.75 44.261 1.98594 46.3542 3.97624 47.452L23.676 58.3179C25.5302 59.3406 27.7766 59.3532 29.6422 58.3513L49.9624 47.4381C51.9869 46.3509 53.25 44.2388 53.25 41.9408L53.25 18.6032C53.25 16.3512 52.0366 14.274 50.075 13.1678L29.7563 1.70992C27.8315 0.624503 25.4762 0.638031 23.564 1.74549Z"
        fill="#F5F1EF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1997 25.7217L15.6619 28.7172C15.6619 28.7172 16.9784 26.7669 18.6014 28.0756C19.8476 29.0803 19.4674 30.8955 19.1575 32.3865C19.0637 32.8373 18.9749 33.2579 18.9414 33.6175C18.7522 35.5864 19.2329 37.1787 20.0184 38.4435C21.0184 40.0527 23.3398 41.3917 25.8321 41.8645C26.0232 41.9 26.2057 41.9271 26.3865 41.9507L26.6462 44.7199H28.5238L28.7768 42.0148C33.0864 41.6653 39.1832 37.4151 36.4697 30.2572C35.222 26.9645 32.5772 22.0473 32.5772 22.0473L32.6073 22.0389C33.6541 21.7704 33.8568 20.401 32.6341 19.6664L33.5085 18.8509L32.2305 18.3223L32.7078 16.5612L29.9626 17.657L29.966 17.6537L29.9693 17.6503C29.9693 17.6503 29.2742 16.188 28.2156 16.1762C27.3245 16.166 26.9041 16.8161 26.9041 16.8161C26.9041 16.8161 26.6662 16.1474 26.0566 15.9651C25.4469 15.7827 24.452 16.1407 24.3264 17.2569C24.184 18.5081 25.1873 19.1514 25.5173 19.2578C25.5474 19.2679 25.5793 19.2781 25.611 19.2882C25.9964 19.4182 26.5373 19.6006 27.5841 19.1548C27.5825 19.7407 27.5808 20.7184 27.5792 21.7147C27.5774 22.6721 27.5758 23.6464 27.5741 24.3016C27.5741 24.5498 27.5758 24.82 27.5792 25.1054C27.5976 26.8142 27.6193 29.0651 26.9878 30.372C26.2492 31.8968 25.4084 32.5317 24.2945 32.4C23.0501 32.2531 22.6246 31.0779 23.0568 28.8979C23.4889 26.718 23.8541 24.5481 22.0401 22.9322C20.2261 21.3162 16.965 21.1389 13.1997 25.7217ZM30.2004 18.7208C30.4801 18.5756 30.8386 18.7175 30.9994 19.0332C31.1601 19.3507 31.063 19.7256 30.7833 19.8691C30.5036 20.0126 30.1452 19.8725 29.9844 19.555C29.8236 19.2392 29.919 18.8644 30.2004 18.7208Z"
        fill="#FF7D00"
      />
    </svg>
  );
};

export default TierBadgePLKNormal;
