import { FC } from 'react';

const TierBadgePLKSuper: FC = ({ size }: any) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 54 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_4388_7964" fill="white">
        <path d="M23.564 1.74549L3.86271 13.1555C1.93616 14.2713 0.75 16.329 0.75 18.5553V41.9881C0.75 44.261 1.98594 46.3542 3.97624 47.452L23.676 58.3179C25.5302 59.3406 27.7766 59.3532 29.6422 58.3513L49.9624 47.4381C51.9869 46.3509 53.25 44.2388 53.25 41.9408L53.25 18.6032C53.25 16.3512 52.0366 14.274 50.0751 13.1678L29.7563 1.70992C27.8315 0.624503 25.4762 0.638031 23.564 1.74549Z" />
      </mask>
      <path
        d="M23.564 1.74549L3.86271 13.1555C1.93616 14.2713 0.75 16.329 0.75 18.5553V41.9881C0.75 44.261 1.98594 46.3542 3.97624 47.452L23.676 58.3179C25.5302 59.3406 27.7766 59.3532 29.6422 58.3513L49.9624 47.4381C51.9869 46.3509 53.25 44.2388 53.25 41.9408L53.25 18.6032C53.25 16.3512 52.0366 14.274 50.0751 13.1678L29.7563 1.70992C27.8315 0.624503 25.4762 0.638031 23.564 1.74549Z"
        fill="#F25600"
      />
      <path
        d="M3.86271 13.1555L2.86038 11.4248H2.86038L3.86271 13.1555ZM23.564 1.74549L22.5617 0.0147883V0.0147884L23.564 1.74549ZM29.7563 1.70992L30.7387 -0.0321803V-0.0321804L29.7563 1.70992ZM50.0751 13.1678L51.0574 11.4257L50.0751 13.1678ZM53.25 18.6032H55.25H53.25ZM53.25 41.9408H51.25H53.25ZM49.9624 47.4381L49.0161 45.6762L49.9624 47.4381ZM29.6422 58.3513L30.5885 60.1132H30.5885L29.6422 58.3513ZM23.676 58.3179L24.642 56.5666L23.676 58.3179ZM3.97624 47.452L4.94219 45.7007L4.94219 45.7007L3.97624 47.452ZM4.86505 14.8862L24.5663 3.47619L22.5617 0.0147884L2.86038 11.4248L4.86505 14.8862ZM28.7739 3.45202L49.0927 14.9099L51.0574 11.4257L30.7387 -0.0321803L28.7739 3.45202ZM51.25 18.6032L51.25 41.9408H55.25L55.25 18.6032H51.25ZM49.0161 45.6762L28.6959 56.5893L30.5885 60.1132L50.9087 49.2001L49.0161 45.6762ZM24.642 56.5666L4.94219 45.7007L3.01029 49.2033L22.7101 60.0691L24.642 56.5666ZM2.75 41.9881V18.5553H-1.25V41.9881H2.75ZM4.94219 45.7007C3.58981 44.9548 2.75 43.5325 2.75 41.9881H-1.25C-1.25 44.9896 0.382076 47.7536 3.01029 49.2033L4.94219 45.7007ZM28.6959 56.5893C27.4283 57.2701 25.9019 57.2615 24.642 56.5666L22.7101 60.0691C25.1586 61.4197 28.125 61.4363 30.5885 60.1132L28.6959 56.5893ZM51.25 41.9408C51.25 43.5023 50.3918 44.9374 49.0161 45.6762L50.9087 49.2001C53.5821 47.7643 55.25 44.9754 55.25 41.9408H51.25ZM49.0927 14.9099C50.4255 15.6616 51.25 17.073 51.25 18.6032H55.25C55.25 15.6295 53.6477 12.8864 51.0574 11.4257L49.0927 14.9099ZM24.5663 3.47619C25.8657 2.72369 27.4661 2.71449 28.7739 3.45202L30.7387 -0.0321804C28.197 -1.46549 25.0868 -1.44762 22.5617 0.0147883L24.5663 3.47619ZM2.86038 11.4248C0.316336 12.8982 -1.25 15.6154 -1.25 18.5553H2.75C2.75 17.0425 3.55598 15.6443 4.86505 14.8862L2.86038 11.4248Z"
        fill="#F5F1EF"
        mask="url(#path-1-inside-1_4388_7964)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.4238 44.5433C35.7572 43.61 40.0238 37.7433 39.4905 29.61C38.9572 21.61 33.3572 15.4766 27.3572 15.3433C24.9572 15.21 21.8905 16.41 21.0905 16.81C16.1572 18.81 13.0905 24.81 13.7572 31.4766C14.4238 39.21 20.2905 44.81 25.7572 44.81C26.2979 44.7424 27.1813 44.709 28.0773 44.6752C28.9488 44.6424 29.8322 44.609 30.4238 44.5433ZM29.9793 42.2776C35.1506 41.509 38.5267 35.3948 37.52 28.6211C36.5133 21.8474 31.505 16.9793 26.3337 17.7479C21.1624 18.5164 17.7863 24.6306 18.793 31.4043C19.7997 38.178 24.808 43.0461 29.9793 42.2776ZM27.951 23.8348C27.5332 24.0216 26.7359 24.2842 26.0878 24.1375C25.3549 23.9716 24.6837 23.4619 24.6255 22.4491C24.5729 21.5334 25.2266 21.0647 25.8663 21.1288C26.4383 21.1861 26.7244 21.7593 26.7244 21.7593C26.7244 21.7593 26.9441 21.1374 27.6109 21.0908C28.3343 21.0402 28.8252 21.5957 29.046 21.916L31.2765 20.8591L30.8323 22.2989L32.0623 22.2578L31.135 23.6223C31.5729 23.8128 31.8489 24.2422 31.7539 24.718C31.7058 24.9588 31.5408 25.1081 31.3898 25.1969C31.9581 25.9974 33.4645 28.1553 34.3322 29.7376C36.511 33.7102 33.9498 36.8445 31.5817 37.7949L31.6062 39.0121L30.3112 39.2248L29.974 38.1569C29.7299 38.1776 29.4846 38.1841 29.2405 38.1757C27.7096 38.1231 26.1964 37.4891 25.4349 36.5603C24.8367 35.8303 24.3848 34.8617 24.2908 33.5877C24.2735 33.3619 24.2811 33.0946 24.2893 32.8079L24.2902 32.7756C24.3177 31.7958 24.3511 30.6049 23.5087 30.0867C22.4093 29.4102 21.8329 30.7822 21.8329 30.7822L20.0592 29.1117C21.8118 25.8225 23.764 25.6179 25.0089 26.4718C26.181 27.2757 26.1996 28.397 26.2216 29.7221L26.2216 29.7226C26.223 29.8046 26.2243 29.8875 26.226 29.971C26.2455 30.9519 26.7504 31.5383 27.3692 31.5444C28.0445 31.5511 28.5289 31.1165 28.7202 30.3986C28.9301 29.6109 28.7531 28.6204 28.5769 27.6343C28.5581 27.5288 28.5392 27.4233 28.5209 27.3182C28.489 27.1365 28.4597 26.9644 28.4337 26.8061C28.3693 26.4083 28.2747 25.8263 28.1801 25.2446L28.1657 25.1559C28.0874 24.6742 28.0101 24.1993 27.951 23.8348Z"
        fill="#F5F1EF"
      />
    </svg>
  );
};

export default TierBadgePLKSuper;
